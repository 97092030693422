const Endpoints = {
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
    refreshToken: '/auth/try-refresh-token',
    forgotPassword: '/auth/forgot-password',
    validateResetPasswordToken: '/auth/validate-reset-password-token',
    resetPassword: '/auth/reset-password',
  },
  user: {
    me: '/user/me',
    search: '/user/search',
    create: '/user/create',
    detail: '/user/get',
    update: '/user/update',
    delete: '/user/delete',
    status: '/user/change-status',
    role: '/user/get-all-roles',
    changePassword: '/user/change-password',
  },
  roles: {
    search: '/roles/search',
    create: '/roles/create',
    detail: '/roles/get',
    delete: '/roles/delete',
    update: '/roles/update',
    status: '/roles/change-status',
    permissions: '/roles/get-all-permissions',
  },
  memberLevel: {
    search: '/member-level/search',
    create: '/member-level/create',
    detail: '/member-level/get',
    update: '/member-level/update',
    status: '/member-level/change-status',
    active: '/member-level/all-active',
    all: '/member-level/all',
  },
  customers: {
    search: '/customer/search',
    create: '/customer/create',
    detail: '/customer/get',
    update: '/customer/update',
    bookings: '/customer/get-customer-bookings',
    survey: '/customer/get-customer-survey',
    feedback: '/customer/get-customer-feedback',
    contact: '/customer/get-customer-contact',
    upgrade: '/member/active-member',
  },
  members: {
    search: '/member/search',
    create: '/member/create',
    detail: '/member/get',
    update: '/member/update',
    updatePoint: '/member/update-point',
    updateLevel: '/member/update-level',
    pointHistory: '/member/get-member-point-history',
    levelHistory: '/member/get-member-level-history',
    bookings: '/member/get-member-bookings',
    survey: '/member/get-member-survey',
    feedback: '/member/get-member-feedback',
    contact: '/member/get-member-contact',
    updateCardStatus: '/member/update-member-card-status',
    export: '/member/export',
  },
  hotelConfig: {
    active: '/hotel-config/get-all-active',
    create: '/hotel-config/create',
    update: '/hotel-config/update',
    search: '/hotel-config/search',
    detail: '/hotel-config/get',
    all: '/hotel-config/get-all',
  },
  pointPolicy: {
    create: '/policy/create',
    detail: '/policy/get',
    update: '/policy/update',
    delete: '/policy/delete',
    search: '/policy/search',
  },
  country: {
    search: '/country/get-country-datas',
  },
  ProductWarranty: {
    getInfo: '/shared/ProductWarrant/GetProductWarrantyInfo',
    active: '/shared/ProductWarrant/ActiveProductWarranty',
    updateCustomerWinPrize: '/shared/ProductWarrant/UpdateCustomerWinPrize',
  },
  booking: {
    details: '/booking/get',
  },
} as const;

export default Endpoints;
