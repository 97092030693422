import { createBrowserRouter } from 'react-router-dom';

// Routes
import Auth from './Auth';
import Main from './Main';
import ProductWarranty from './ProductWarranty';

const router = createBrowserRouter([
 // Auth,
  //Main,
  ProductWarranty,
  // Fallback
]);

export default router;
