import Loadable from 'components/core/Loadable';
import PublicRoute from 'components/core/PublicRoute';
import WarrantyLayout from 'layouts/WarrantyLayout';
import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

// Auth
const ProductWarrantyPage = Loadable(lazy(() => import('pages/ProductWarranty')));


const ProductWarranty: RouteObject = {
  path: 'bao-hanh',
  element: <WarrantyLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="login" />,
    },
    {
      path: ':id',
      element: (
        <PublicRoute>
          <ProductWarrantyPage />
        </PublicRoute>
      ),
    },
    // {
    //   path: 'register',
    //   element: <Register />,
    // },
    // {
    //   path: '/auth/forgot-password',
    //   element: <PasswordRecovery />,
    // },
    // {
    //   path: '/auth/reset-password',
    //   element: <PasswordReset />,
    // },
  ],
};

export default ProductWarranty;
