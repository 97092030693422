import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Outlet } from 'react-router-dom';

const WarrantyLayout = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flexGrow: 1,
        display: 'grid',
      }}
    >
      <Container maxWidth="sm" sx={{padding: 0}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Box>
      </Container>

      <Copyright />
    </Box>
  );
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '} {new Date().getFullYear()}{' '}
      <Link color="inherit" href="/">
        Vtech
      </Link>
      {'.'} Pin thay thế iphone thế hệ mới. Hotline: 082.700.6666
    </Typography>
  );
};

export default WarrantyLayout;
