import Endpoints from 'constants/endpoints';
import type { Permission, User } from 'types/auth';
import type { HttpResponse } from 'types/shared';
import HttpClient from 'utils/HttpClient';

// Sign in with email and password
interface SignInParams {
  userName: string;
  password: string;
}
interface SignInResponse {
  refreshToken: string;
  token: string;
  userId: number;
  name: string;
}
export const signIn = async (params: SignInParams) => {
  return {
    name: "",
    token: ""
  } as SignInResponse;
  return HttpClient.post<typeof params, SignInResponse>(
    Endpoints.auth.login,
    params
  );
};

// Sign out
export const signOut = async () => {
  return HttpClient.post(Endpoints.auth.logout);
};

// Forgot password
interface ForgotPasswordParams {
  username: string;
}
export const forgotPassword = async (params: ForgotPasswordParams) => {
  return HttpClient.get<null, HttpResponse<User>>(
    Endpoints.auth.forgotPassword,
    { params }
  );
};

// Get user profile
export const getUser = async () => {
  return HttpClient.get<null, HttpResponse<User>>(Endpoints.user.me);
};

// Get list permissions
export const getPermissions = async () => {
  return HttpClient.get<Permission[]>(Endpoints.roles.permissions);
};

// Change password
interface ChangePasswordParams {
  currentPassword: string;
  newPassword: string;
}
export const changePassword = async (params: ChangePasswordParams) => {
  return HttpClient.post<typeof params, HttpResponse>(
    Endpoints.user.changePassword,
    params
  );
};
